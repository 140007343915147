var SPMail = (function () {
    /* Spam Mail prevention */
    return {
        SendEmail: function (address, domain) {
            document.location.href = "mailto:" + address.replace(/##/g, "'") + "@" + domain.replace(/##/g, "'");
        },

        // replaces the specially crafted span with the @ sign
        UpdateDisplayText: function () {
            var matches = document.querySelectorAll("span.spamproof_insert");
            for (var i = 0; i < matches.length; i++) {
                var match = matches[i];
                var content = document.createTextNode("@");
                match.parentNode.replaceChild(content, match);
            }
        }
    };
})();

document.onreadystatechange = function () {
    if (document.readyState == "complete") {
        SPMail.UpdateDisplayText();
    }
};
