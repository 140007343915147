// Production steps of ECMA-262, Edition 5, 15.4.4.14
// Reference: http://es5.github.io/#x15.4.4.14
if (!Array.prototype.indexOf) {
    Array.prototype.indexOf = function (searchElement, fromIndex) {

        var k;

        // 1. Let O be the result of calling ToObject passing
        //    the this value as the argument.
        if (this == null) {
            throw new TypeError('"this" is null or not defined');
        }

        var O = Object(this);

        // 2. Let lenValue be the result of calling the Get
        //    internal method of O with the argument "length".
        // 3. Let len be ToUint32(lenValue).
        var len = O.length >>> 0;

        // 4. If len is 0, return -1.
        if (len === 0) {
            return -1;
        }

        // 5. If argument fromIndex was passed let n be
        //    ToInteger(fromIndex); else let n be 0.
        var n = +fromIndex || 0;

        if (Math.abs(n) === Infinity) {
            n = 0;
        }

        // 6. If n >= len, return -1.
        if (n >= len) {
            return -1;
        }

        // 7. If n >= 0, then Let k be n.
        // 8. Else, n<0, Let k be len - abs(n).
        //    If k is less than 0, then let k be 0.
        k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

        // 9. Repeat, while k < len
        while (k < len) {
            // a. Let Pk be ToString(k).
            //   This is implicit for LHS operands of the in operator
            // b. Let kPresent be the result of calling the
            //    HasProperty internal method of O with argument Pk.
            //   This step can be combined with c
            // c. If kPresent is true, then
            //    i.  Let elementK be the result of calling the Get
            //        internal method of O with the argument ToString(k).
            //   ii.  Let same be the result of applying the
            //        Strict Equality Comparison Algorithm to
            //        searchElement and elementK.
            //  iii.  If same is true, return k.
            if (k in O && O[k] === searchElement) {
                return k;
            }
            k++;
        }
        return -1;
    };
}

function doHeadSiteSearch() {
    var searchText = $("#inputSearchKeywords").val();
    searchText = searchText.replace("‘", "%27");
    searchText = searchText.replace("’", "%27");
    var searchUrl = "/search?qu=" + decodeURIComponent(searchText);
    window.location.replace(searchUrl);
}

$(function () {
    $(".global-tabs").each(function () {
        var _this = $(this),
            tabs = _this.find(".global-tab"),
            tabContents = _this.find(".global-tab-content");

        tabs.on("click", function (e, isProgrammatic) {
            e.preventDefault();

            var index = tabs.index(this);
            tabs.removeClass("is-active");
            tabContents.removeClass("is-active");
            tabs.eq(index).addClass("is-active");
            tabContents.eq(index).addClass("is-active");
            _this.trigger("globalTabSwitched", [index, isProgrammatic]);
        });
    });
});

(function ($) {
    'use strict';

    //On DOM Ready
    $(function () {

        $(".global-nav-link").each(function () {
            var _this = $(this);
            if (window.location.pathname.indexOf(_this.attr('href')) > -1) {
                _this.addClass('active');
                return false;
            } else {
                _this.removeClass('active');
            }
        });


        //Mobile Trigger
        var $mobileTrigger = $('.nav-mobile-toggle'),
            $mainNavigation = $('.global-site-nav');

        $mobileTrigger.on('click.mobileTrigger', function (ev) {
            ev.preventDefault();


            if ($mobileTrigger.hasClass('is-active')) {
                $mobileTrigger.removeClass('is-active');
                $mainNavigation.removeClass('is-active-mobilenav');
            }

            else {
                $mobileTrigger.addClass('is-active');
                $mainNavigation.addClass('is-active-mobilenav');

                //Close SearchBox if opened
                $globalBody.removeClass('js-global-search-active');
                $modalWrapper.removeClass('is-active');
                $globalSearch.removeClass('is-active');
            }

        });


        //Global Search Trigger
        var $globalSearch = $('.site-search'),
            $searchTrigger = $('.js-global-search'),
            $globalBody = $('body'),
            $modalWrapper = $('.modal-overlay');

        $searchTrigger.on('click.globalSearch', function (ev) {

            ev.preventDefault();

            if ($globalBody.hasClass('js-global-search-active')) {
                //Close Mobile Navigation if it's opened
                $globalBody.removeClass('js-global-search-active');
                $modalWrapper.removeClass('is-active');
                $globalSearch.removeClass('is-active');
            }

            else {
                $globalBody.addClass('js-global-search-active');
                $modalWrapper.addClass('is-active');
                $globalSearch.addClass('is-active');

                $mobileTrigger.removeClass('is-active');
                $mainNavigation.removeClass('is-active-mobilenav');
            }
        });

        $modalWrapper.on('click', function () {
            $globalBody.removeClass('js-global-search-active');
            $modalWrapper.removeClass('is-active');
            $globalSearch.removeClass('is-active');
        });


        //Find All Toggle Components and attach event handler
        var $accordionSingle = $('[data-accordion-single]');


        //check to see if we have any accordions on the page, if so attach event handler
        if ($accordionSingle.length > 0) {
            accordionSingleInit($accordionSingle);
        }

        function accordionSingleInit($accordionSingle) {

            $accordionSingle.each(function () {

                var _self = $(this),
                    $accordionContent,
                    accordionContentData = _self.data('accordion-single');

                (typeof (accordionContentData) === 'string') ? (
                    $accordionContent = $(accordionContentData)

                ) : (

                    $accordionContent = _self.next()
                );


                _self.on('click.accordion', function () {
                    _self.toggleClass('is-active');
                    $accordionContent.stop().slideToggle();
                });

            });
        }


        //AccordionMultple Module

        var $accordionMultiple = $('[data-accordion-multiple]');

        //check to see if we have any accordions on the page, if so attach event handler
        if ($accordionMultiple.length > 0) {
            accordionMultipleInit($accordionMultiple);
        }


        function accordionMultipleInit($accordionMultiple) {
            $accordionMultiple.each(function () {

                var _self = $(this),
                    $navTriggers = _self.find('.js-accordion-trigger'),
                    $navMenus = _self.find('.js-accordion-menu'),
                    clickedIndex;

                $navTriggers.on('click', function () {

                    var $currentItem = $(this),

                        clickedIndex = $navTriggers.index($currentItem);


                    if ($currentItem.hasClass('is-active-menu')) {
                        $currentItem.removeClass('is-active-menu');

                        closeAll($navTriggers, $navMenus);
                    }

                    else {
                        closeAll($navTriggers, $navMenus);
                        openNavItem($currentItem, clickedIndex, $navMenus);
                    }

                });
            });
        }

        function closeAll($navTriggers, $navMenus) {

            $navTriggers.removeClass('is-active-menu');

            $navMenus.slideUp();
        }

        function openNavItem(navItem, navIndex, $navMenus) {

            navItem.addClass('is-active-menu');

            $navMenus.eq(navIndex).slideDown();
        }

    });

})(jQuery);

//https://davidwalsh.name/query-string-javascript
function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

routerBase = {

    push: function () {
        if (!history.pushState) {
            console.log('history api not supported');
            return;
        }

        history.pushState(this.state, "", this.urlFragment(this.state));
    },

    pop: function () {
        history.back();
    },

    urlFragment: function (stateObject) {
        var url = "?";
        var stateObjectCopy = $.extend({}, stateObject);

        //convert arrays to CSV to improve the appearance of the url
        //remove empty parameters
        $.each(stateObjectCopy, function (index, value) {
            if (Array.isArray(value)) {
                if (!value.length)
                    delete stateObjectCopy[index];
                else
                    stateObjectCopy[index] = value.join("~");
            }
            else {
                if (value !== 0 && !value) {
                    delete stateObjectCopy[index];
                }
            }
        });

        return url + $.param(stateObjectCopy);
    }
}